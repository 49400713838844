import React, { useEffect } from "react";

import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Icon from "../../../components/custom-widgets/icon";

import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import HeroChevron from "../../../components/hero/hero-chevron";

// Helper Functions
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import MobileAppDownloadBtn from "../../../components/mobile-online-banking/mobile-app-download-btn";

const WaFdSportsSeattleKraken = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/about-us/wafd-sports/seattle-kraken/hero-kraken-contest-091323-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const heroChevronData = {
    id: "seattle-kraken-hero",
    chevronBgClass: "bg-kraken-primary-blue",
    ...getHeroImgVariables(imgData),
    altText: "Hockey player holding a WaFd Seattle Kraken debit card.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Open a *Free Checking account with a Seattle Kraken debit card!"
          }
        },
        {
          id: 2,
          button: {
            class: "btn-white",
            text: "Open an Account",
            url: "/open-an-account"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/about-us",
      title: "About Us"
    },
    {
      id: 2,
      url: "/about-us/wafd-sports",
      title: "WaFd Sports"
    },
    {
      id: 3,
      active: true,
      title: "Seattle Kraken"
    }
  ];

  const title = "Seattle Kraken Debit Card";
  const description =
    "Check out the Seattle Kraken sponsored debit card at WaFd Bank and enter the sweepstakes to win suite tickets to a hockey game in Seattle!";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/about-us/wafd-sports/seattle-kraken"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-kraken-contest-091323.jpg"
      }
    ]
  };

  const krakenVideoData = {
    id: "free-checking-video",
    vimeoId: "1014562946",
    videoTitle: "Jamie Oleksiak on the Best Bank to Open a Checking Account - WaFd Bank",
    class: "iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container section-padding-adjusted">
        <h1>Seattle Kraken</h1>

        <div className="row">
          <div className="col-md-6">
            <h2 className="text-success">Proper Checking Technique</h2>
            <h4>Love the Seattle Kraken? Open a Free Checking* account with a Seattle Kraken debit card.</h4>
            <div className="row my-3">
              <div className="col-lg-6">
                <StaticImage
                  src="../../../images/cards/credit-cards/thumbnail-debit-kraken-horizontal.jpg"
                  alt="WaFd Bank Kraken theme debit card"
                  placeholder="blurred"
                  quality="100"
                />
              </div>
              <div className="col-lg-6 mt-3 mt-lg-0">
                <div className="mb-3">
                  <a
                    id="open-bank-account-online-cta"
                    href="/open-an-account"
                    className="btn btn-primary w-100 w-sm-auto"
                  >
                    Open an Account
                  </a>
                </div>
                <div>
                  <Link
                    id="compare-accounts-cta"
                    to="/personal-banking/checking-account"
                    className="btn btn-link w-100 w-sm-auto"
                  >
                    Compare Accounts
                  </Link>
                </div>
              </div>
            </div>
            <p className="text-sm text-gray-60">
              *Only $25 initial deposit to open. Nonsufficient funds charge may apply.
            </p>
          </div>
          <div className="col-md-6 text-center">
            <VimeoVideoFrame {...krakenVideoData} />
            <h4 className="text-left text-green-60">
              Seattle Kraken defenseman Jamie Oleksiak shows you why checking doesn't have to hurt.
            </h4>
          </div>
        </div>
      </section>
      <section className="container border-top">
        <div className="row">
          <div className="col-sm-6 col-lg-4 mb-3">
            <StaticImage
              src="../../../images/third-party/kraken/thumbnail-kraken-suite-winners-brad-040124.jpg"
              alt="Kraken debit card suite winners with Brad Goode, WaFd Bank Chief Marketing & Communications Officer."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <StaticImage
              src="../../../images/third-party/kraken/thumbnail-kraken-suite-winners-jamie-040124.jpg"
              alt="Kraken debit card suite winners with Kraken player Jamie Oleksiak."
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-lg-4">
            <h4 className="mb-0">
              <strong className="text-success">Congrats to our 2023-24 Kraken debit card suite winners.</strong> They
              enjoyed a great game with friends and family and met the Big Rig, defenseman Jamie Oleksiak in the WaFd
              suite after the game.
            </h4>
          </div>
        </div>
      </section>
      <div className="bg-kraken-light-blue mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pt-3">
              <h2>Go Kraken mode on your WaFd App!</h2>
              <p>Download or update your WaFd app, go to your Menu Settings and select Change Theme.</p>
              <div className="d-block d-md-none text-center">
                <StaticImage
                  src="../../../images/kraken-mobile-theme.png"
                  alt="Kraken mobile theme"
                  placeholder="blurred"
                  quality="100"
                />
              </div>
              <p className="text-center text-sm-left">
                <strong>Get the WaFd Bank mobile app</strong>
              </p>
              <div className="row justify-content-center justify-content-sm-start px-sm-2">
                <div className="col-auto mb-3 mb-sm-0 px-2">
                  <MobileAppDownloadBtn
                    idName="google-play-store"
                    storeBtn="google"
                    url="https://play.google.com/store/apps/details?id=com.washingtonfederal.mobilebanking&amp;hl=en_US"
                  />
                  <div className="text-sm text-gray-80 text-center text-md-left">For Android devices</div>
                </div>
                <div className="col-auto mb-3 mb-sm-0 px-2">
                  <MobileAppDownloadBtn
                    idName="apple-store"
                    storeBtn="apple"
                    url="https://apps.apple.com/us/app/wafd-bank/id1054549841"
                  />
                  <div className="text-sm text-gray-80 text-center text-md-left">For Apple devices</div>
                </div>
                <div className="col-sm-auto px-2 d-none d-sm-block">
                  <StaticImage
                    src="../../../images/wafd-mobile-app-qr-code.png"
                    alt="WaFd App QR Code"
                    placeholder="blurred"
                    quality="100"
                  />
                  <div className="text-sm text-gray-80 text-center text-md-left">Scan to download</div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-none d-md-flex justify-content-center align-items-center">
              <StaticImage
                src="../../../images/kraken-mobile-theme.png"
                alt="Kraken mobile theme"
                placeholder="blurred"
                quality="100"
                style={{ marginTop: "-30px", marginBottom: "-40px" }}
              />
            </div>
          </div>
        </div>
      </div>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 border-md-right border-gray-50 border-bottom border-md-bottom-0 py-3">
              <p className="font-italic text-secondary">
                &ldquo;WaFd Bank is a distinguished addition to our list of Founding Partners and we could not be more
                excited for our guests to experience the WaFd Bank Club at{" "}
                <a
                  id="climate-pledge-arena-link"
                  href="https://climatepledgearena.com/seattle-kraken/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Climate Pledge Arena
                </a>
                . WaFd's commitment to helping neighborhoods flourish and supporting communities aligns with our mission
                and we look forward to collaborating in these areas as we move forward.&rdquo;
              </p>
              <div className="d-flex justify-content-end align-items-center">
                <div className="mr-3 text-right">
                  <span className="font-weight-bold">Tim Leiweke</span>
                  <br />
                  CEO, Oak View Group
                </div>
                <StaticImage
                  src="../../../images/employees/tim-leiweke-portrait.png"
                  alt="Tim Leiweke"
                  placeholder="blurred"
                  quality="100"
                />
              </div>
            </div>

            <div className="col-md-6 py-3">
              <p className="font-italic text-secondary">
                &ldquo;Like many hockey fans, we have watched with awe as the Seattle Kraken team has accomplished what
                many said was impossible. They are constructing both an arena and a franchise on the fundamentals of
                integrity, hard work and innovation. We are honored to be a Founding Partner with the Seattle Kraken to
                play a small role in bringing hockey back to Seattle.&ldquo;
              </p>
              <div className="d-flex justify-content-end align-items-center">
                <div className="mr-3 text-right">
                  <span className="font-weight-bold">Brent Beardall</span>
                  <br />
                  CEO, WaFd Bank
                </div>
                <StaticImage
                  src="../../../images/employees/headshot-brent-beardall-gray-5-03282023.jpg"
                  alt="Brent Beadall"
                  placeholder="blurred"
                  quality="100"
                  style={{ width: "102px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container text-center d-none d-xs-block">
        <div className="row no-gutters">
          <div className="col-xs-6 col-md-4">
            <StaticImage
              className="mr-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-06-090823.jpg"
              alt="Seattle Kraken Players at Climate Pledge Arena"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-xs-6 col-md-4">
            <StaticImage
              className="mr-md-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-01-090823.jpg"
              alt="Seattle Kraken Players at Climate Pledge Arena"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6 col-md-4">
            <StaticImage
              className="mr-1 mr-md-0 pr-md-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-10-090823.jpg"
              alt="WaFd Bank Seattle Kraken Brand Launch Event"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6">
            <StaticImage
              className="mr-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-02-090823.jpg"
              alt="Seattle Kraken Players at Climate Pledge Arena"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6">
            <StaticImage
              className="mr-1 mr-md-0 pr-md-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-08-090823.jpg"
              alt="Seattle Kraken Players at Climate Pledge Arena"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6 col-md-4">
            <StaticImage
              className="mr-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-05-090823.jpg"
              alt="Seattle Kraken Players at Climate Pledge Arena"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6 col-md-4">
            <StaticImage
              className="mr-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-11-090823.jpg"
              alt="WaFd Walt Seattle Kraken Fan"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6 col-md-4">
            <StaticImage
              className="mr-1 mr-md-0 pr-md-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-04-090823.jpg"
              alt="Seattle Kraken Players at Climate Pledge Arena"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6">
            <StaticImage
              className="mr-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-12-090823.jpg"
              alt="WaFd Bank Seattle Kraken Brand Launch Event"
              placeholder="blurred"
              quality="100"
            />
          </div>
          <div className="col-12 col-xs-6">
            <StaticImage
              className="mr-1 mr-md-0 pr-md-1"
              src="../../../images/third-party/kraken/thumbnail-kraken-09-090823.jpg"
              alt="WaFd Bank branded Seattle Kraken Zamboni "
              placeholder="blurred"
              quality="100"
            />
          </div>
        </div>
      </section>
      <section className="container d-block d-xs-none">
        <StaticImage
          src="../../../images/third-party/kraken/thumbnail-kraken-06-090823.jpg"
          alt="Seattle Kraken Players at Climate Pledge Arena"
          placeholder="blurred"
          quality="100"
        />
        <StaticImage
          src="../../../images/third-party/kraken/thumbnail-kraken-01-090823.jpg"
          alt="Seattle Kraken Players at Climate Pledge Arena"
          placeholder="blurred"
          quality="100"
        />
        <StaticImage
          src="../../../images/third-party/kraken/thumbnail-kraken-01-090823.jpg"
          alt="Seattle Kraken Players at Climate Pledge Arena"
          placeholder="blurred"
          quality="100"
        />
        <StaticImage
          src="../../../images/third-party/kraken/thumbnail-kraken-09-090823.jpg"
          alt="WaFd Bank branded Seattle Kraken Zamboni"
          placeholder="blurred"
          quality="100"
        />
        <StaticImage
          src="../../../images/third-party/kraken/thumbnail-kraken-02-090823.jpg"
          alt="Seattle Kraken Players at Climate Pledge Arena "
          placeholder="blurred"
          quality="100"
        />
      </section>
    </SecondaryLanding>
  );
};

export default WaFdSportsSeattleKraken;
